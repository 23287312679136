window.$ = window.jQuery = require("jquery"); //v3.7.0
window.AOS = require("aos/dist/aos.js");
window.bootstrap = require("bootstrap/dist/js/bootstrap.bundle.js"); //v5.3.0
/**
  * Animation on scroll
  */
window.addEventListener('load', () => {
  AOS.init({
    duration: 1000,
    easing: "fade-up",
    once: true,
    mirror: false
  });
});

